import { api } from './rootApi';
import type { Form, FormSmall, CreateFormInput, EditFormInput } from '@/types/Form';

export const cartApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFormById: builder.query<Form, string>({
            query: (id) => `ticket-info-forms/${id}`,
            providesTags: ['Form'],
        }),
        getFormsByOrganizationId: builder.query<FormSmall[], string>({
            query: (organizationId) => `ticket-info-forms/organization/${organizationId}`,
            providesTags: ['Form'],
        }),
        createForm: builder.mutation<Form, CreateFormInput>({
            query: (body) => ({
                url: 'ticket-info-forms',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Form'],
        }),
        editForm: builder.mutation<Form, EditFormInput>({
            query: ({ id, ...body }) => ({
                url: `ticket-info-forms/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Form'],
        }),
        deleteForm: builder.mutation<{ message: string; status: number }, string>({
            query: (id) => ({
                url: `ticket-info-forms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Form'],
        }),
    }),
});

export const {
    useGetFormByIdQuery,
    useGetFormsByOrganizationIdQuery,
    useCreateFormMutation,
    useEditFormMutation,
    useDeleteFormMutation,
} = cartApi;
