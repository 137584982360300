import { useNavigate } from 'react-router-dom';
import { Card, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { DeleteDialog } from '@/components/ui/DeleteDialog';
import { Button } from '@/components/ui/button';
import { useDeleteFormMutation } from '@/services/formsApi';
import type { FormSmall } from '@/types/Form';

interface Props {
    form: FormSmall;
}

export default function FormCard({ form }: Props): JSX.Element {
    const [deleteForm, deleteFormState] = useDeleteFormMutation();
    const navigate = useNavigate();

    return (
        <Card
            className="flex flex-1 h-full flex-col hover:bg-accent hover:cursor-pointer relative"
            onClick={() => navigate(`/admin/forms/${form.id}`)}
        >
            <CardHeader>
                <CardTitle>{form.name}</CardTitle>
            </CardHeader>
            <CardFooter className="justify-end gap-2">
                <DeleteDialog
                    deleteItem={() => deleteForm(form.id).unwrap()}
                    isLoading={deleteFormState.isLoading}
                    successMessage="Ticket form deleted successfully"
                >
                    <Button variant="destructive">Delete</Button>
                </DeleteDialog>
            </CardFooter>
        </Card>
    );
}
