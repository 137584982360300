import { useAppSelector } from '@/hooks/store';
import { selectCurrentEditForm } from '@/features/currentEditForm/currentEditFormSlice';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { FormQuestion } from '@/components/Forms/FormQuestion';

export default function PreviewTicketForm(): JSX.Element {
    const navigate = useNavigate();
    const { currentEditForm } = useAppSelector(selectCurrentEditForm);

    const handleLeavePreview = () => {
        if (!currentEditForm) {
            return;
        }
        if (currentEditForm.id === 'create') {
            navigate('/admin/forms/create');
        } else {
            navigate(`/admin/forms/${currentEditForm.id}?preview=true`);
        }
    };

    if (!currentEditForm) {
        return (
            <div className="flex flex-col gap-2 items-center pt-8">
                <h2 className="text-2xl">No form selected</h2>
                <Button onClick={() => navigate('/admin/forms')}>Go back</Button>
            </div>
        );
    }

    return (
        <div className="w-full max-w-3xl mx-auto px-4 md:px-6 py-8 md:py-16">
            <div className="flex flex-col gap-2 min-w-3xl">
                <div className="flex flex-row justify-between mb-2">
                    <h1 className="text-3xl font-semibold tracking-tight sm:text-4xl">{currentEditForm.name}</h1>
                    <Button variant="outline" onClick={handleLeavePreview}>
                        Leave Preview
                    </Button>
                </div>
                {currentEditForm.template.map((q) => (
                    <FormQuestion key={q.id} question={q} />
                ))}
            </div>
        </div>
    );
}
