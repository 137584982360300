import { useNavigate } from 'react-router-dom';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useCurrentOrganization } from '@/hooks/apiHooks';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { PageHeader } from '@/components/PageHeader';
import { useGetFormsByOrganizationIdQuery } from '@/services/formsApi';
import FormCard from '@/components/Forms/FormCard';

export default function TicketForms(): JSX.Element {
    const currentOrganization = useCurrentOrganization();
    const navigate = useNavigate();
    const { data, isLoading } = useGetFormsByOrganizationIdQuery(currentOrganization?.id ?? '');

    if (isLoading) {
        return <CenteredSpinner />;
    }

    return (
        <div className="flex flex-col items-center">
            <PageHeader>
                <Button onClick={() => navigate('/admin/forms/create')}>
                    <Plus className="h-5 w-5 mr-2" />
                    <p>Create New Ticket Form</p>
                </Button>
            </PageHeader>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4 lg:max-w-7xl">
                {data?.map((form) => <FormCard key={form.id} form={form} />)}
            </div>
        </div>
    );
}
