import { useNavigate, useParams } from 'react-router-dom';

import { Spinner } from '@/components/ui/spinner';
import { useGetEventByIdQuery } from '@/services/eventsApi';
import { EventItems } from '@/components/Events/EventItems';
import {
    eventItemToEventItemInput,
    type CreateEventItem,
    type EventItemInput,
    type PutEventItem,
} from '@/types/EventItem';
import {
    EVENT_BANNER_ASPECT_RATIO,
    EVENT_BANNER_PLACEHOLDER_BLURHASH,
    EVENT_BANNER_PLACEHOLDER_SRC,
    formatDate,
    getEventBannerSrc,
} from '@/lib/utils';
import {
    useCreateEventItemMutation,
    useDeleteEventItemMutation,
    useEditEventItemMutation,
} from '@/services/eventItemApi';
import { errorToast, errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { ExpandableText } from '@/components/ui/ExpandableText';
import { PreviewImage } from '@/components/PreviewImage';
import { InfoIcon, MapPinIcon, PencilIcon, TicketIcon, UsersIcon } from 'lucide-react';
import { AttendeesDialog } from '@/components/Events/AttendeesDialog';

export default function Event(): JSX.Element {
    const navigate = useNavigate();
    const { eventId } = useParams() as { eventId: string };
    const { data, isLoading } = useGetEventByIdQuery(eventId);
    const [postEventItem, postEventItemState] = useCreateEventItemMutation();
    const [putEventItem, putEventItemState] = useEditEventItemMutation();
    const [deleteEventItem, deleteEventItemState] = useDeleteEventItemMutation();

    const setEventItem = (eventItem: EventItemInput, index: number | undefined) => {
        const isNewEventItem = index === undefined;
        if (isNewEventItem) {
            const createEventItem: CreateEventItem = { ...eventItem, event: eventId };
            postEventItem(createEventItem)
                .unwrap()
                .then(() => successToast('Ticket type created successfully'))
                .catch(errorToastFromCatch);
        } else {
            const selectedEventItem = data?.eventItems[index];
            if (!selectedEventItem) {
                errorToast('The ticket type you are trying to update does not exist');
                return;
            }
            const updateEventItem: PutEventItem = { ...eventItem, id: selectedEventItem.id };
            putEventItem(updateEventItem)
                .unwrap()
                .then(() => successToast('Ticket type updated successfully'))
                .catch(errorToastFromCatch);
        }
    };

    const removeEventItem = async (index: number) => {
        const selectedEventItem = data?.eventItems[index];
        if (!selectedEventItem) {
            errorToast('The ticket type you are trying to remove does not exist');
            return;
        }
        deleteEventItem(selectedEventItem.id).unwrap().catch(errorToastFromCatch);
    };

    if (isLoading || !data) {
        return (
            <div className="flex justify-center items-center h-full w-full">
                <Spinner size="lg" />
            </div>
        );
    }
    return (
        <div className="w-full flex justify-center my-4">
            <div className="w-[820px] space-y-4">
                <PreviewImage
                    url={data.bannerBlurhash ? getEventBannerSrc(data.id) : EVENT_BANNER_PLACEHOLDER_SRC}
                    blurHash={data.bannerBlurhash ?? EVENT_BANNER_PLACEHOLDER_BLURHASH}
                    className="w-full rounded-lg"
                    aspectRatio={EVENT_BANNER_ASPECT_RATIO}
                />
                <div className="flex justify-between items-center">
                    <div>
                        <h1 className="text-3xl">{data.title}</h1>
                        <div className="flex items-center space-x-4 text-sm text-gray-600">
                            <div className="flex items-center">
                                <TicketIcon className="h-4 w-4 mr-1" />
                                <span>
                                    {data.ticketsSold} / {data.seatCount}
                                </span>
                            </div>
                            <div className="flex items-center">
                                <UsersIcon className="h-4 w-4 mr-1" />
                                <span>{((data.ticketsSold / data.seatCount) * 100).toFixed(0)}% Full</span>
                            </div>
                        </div>
                        <p className="flex gap-2 items-center">
                            <MapPinIcon className="h-4 w-4" />
                            {data.place.name}
                        </p>
                        <p>{data.organization.name}</p>
                    </div>
                    <div className="flex space-x-2">
                        <p>{formatDate(data.startDate)}</p>
                        <p>-</p>
                        <p>{formatDate(data.endDate)}</p>
                    </div>
                </div>
                <div className="flex justify-between w-full">
                    <AttendeesDialog eventId={eventId} eventTitle={data.title} />
                    <div className="flex gap-2 items-center">
                        {data.seatMapId && (
                            <Button
                                className="gap-2"
                                variant="outline"
                                onClick={() => navigate(`/admin/events/${eventId}/details`)}
                            >
                                <InfoIcon className="h-4 w-4" />
                                <p>Details</p>
                            </Button>
                        )}
                        <Button className="gap-2" onClick={() => navigate(`/admin/events/${eventId}/edit`)}>
                            <PencilIcon className="h-4 w-4" />
                            <p>Edit</p>
                        </Button>
                    </div>
                </div>
                <ExpandableText text={data.description} lines={5} />
                <div className="space-y-2">
                    <p className="text-lg font-semibold">Ticket types</p>
                    {postEventItemState.isLoading || putEventItemState.isLoading || deleteEventItemState.isLoading ? (
                        <div className="flex justify-center items-center">
                            <Spinner size="lg" />
                        </div>
                    ) : (
                        <EventItems
                            eventItems={data.eventItems.map(eventItemToEventItemInput)}
                            setEventItem={setEventItem}
                            removeEventItem={removeEventItem}
                            publishDate={data?.publishDate ?? undefined}
                            endDate={data?.endDate}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
