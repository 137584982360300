import { useMemo } from 'react';

import type { User, UserResponse } from '@/types/User';
import type { RootState } from '@/app/store';
import { useSelector } from 'react-redux';
import { userApi } from '@/services/userApi';
import { multiCompare } from '@/lib/utils';
import { RoleType, type Role, type RoleResponse } from '@/types/Role';
import { organizationApi } from '@/services/organizationApi';
import type { OrganizationSmall } from '@/types/Organization';
import {
    selectCurrentOrganization,
    setCurrentOrganization,
} from '@/features/currentOrganization/currentOrganizationSlice';
import { useAppDispatch } from './store';
import type { PlaceResponse } from '@/types/Place';
import type { UserState } from '@/features/user/userSlice';

export const useAuth = (): {
    user: User | undefined;
    accessToken: string | undefined;
    isAuthenticated: boolean;
} => {
    const { user, accessToken } = useSelector(selectAuth);

    return useMemo(
        () => ({
            user,
            accessToken,
            isAuthenticated: accessToken !== undefined,
        }),
        [user, accessToken],
    );
};

export const selectAuth = (state: RootState): UserState => state.auth;

export const useAllOrganizations = (): OrganizationSmall[] => {
    const organizations = organizationApi.useGetOrganizationsQuery({}).data?.data;
    return organizations ?? [];
};

export const useMyOrganizations = (): OrganizationSmall[] => {
    const roles = useMyRoles();
    const allOrganizations = useAllOrganizations();
    const organizationIds = roles
        ?.filter((role) => multiCompare(role.roleType, [RoleType.ADMIN, RoleType.REPRESENTATIVE]))
        .map((role) => role.organizationId);
    const myOrganizations = allOrganizations?.filter((org) => organizationIds?.includes(org.id));
    return myOrganizations ?? [];
};

export const useCurrentOrganization = (): OrganizationSmall | undefined => {
    const dispatch = useAppDispatch();
    const { currentOrganization } = useSelector(selectCurrentOrganization);
    const firstOrganization = useMyOrganizations()[0];
    if (currentOrganization !== undefined) {
        return currentOrganization;
    }
    if (firstOrganization !== undefined) {
        dispatch(setCurrentOrganization(firstOrganization));
        return firstOrganization;
    }
    return undefined;
};

export const useCurrentOrganizationRoles = (): { roles: RoleResponse[]; isLoading: boolean } => {
    const currentOrganization = useCurrentOrganization();
    const roles = organizationApi.useGetRolesByOrganizationIdQuery(currentOrganization?.id ?? '', {
        skip: currentOrganization === undefined,
    });
    return { roles: roles.data?.data ?? [], isLoading: roles.isLoading };
};

export const useCurrentOrganizationPlaces = (): { places: PlaceResponse[]; isLoading: boolean } => {
    const currentOrganization = useCurrentOrganization();
    const { data, isLoading } = organizationApi.useGetPlacesByOrganizationIdQuery(currentOrganization?.id ?? '', {
        skip: currentOrganization === undefined,
    });
    return { places: data ?? [], isLoading };
};

export const useMe = (): UserResponse | undefined => {
    const { user, accessToken } = useSelector(selectAuth);
    const me = userApi.useGetUserByIdQuery(user?.id ?? '', {
        skip: user === undefined || accessToken === undefined,
    }).data;
    return me;
};

export const useMyRoles = (): Role[] | undefined => {
    const me = useMe();
    const roles = me?.roles;
    return roles;
};

export const useIsAdmin = (): boolean | undefined => {
    const myRoles = useMyRoles();

    return myRoles?.map((role) => role.roleType).some((type) => type === RoleType.ADMIN);
};

export const useIsRepresentative = (): boolean | undefined => {
    const myRoles = useMyRoles();

    return myRoles?.map((role) => role.roleType).some((type) => type === RoleType.REPRESENTATIVE);
};
