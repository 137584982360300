import type { OrganizationSmall } from './Organization';

export interface FormSmall {
    id: string;
    name: string;
}

export interface Form {
    id: string;
    name: string;
    template: Question[];
    organization: OrganizationSmall;
}

export interface CreateFormInput {
    name: string;
    template: Question[];
    organizationId: string;
}

export interface EditForm {
    id: string;
    name: string;
    template: Question[];
}

export interface EditFormInput {
    id: string;
    name?: string;
    template?: Question[];
}
export type ChoiceQuestionType = 'SINGLE_CHOICE' | 'MULTIPLE_CHOICE';
export type QuestionType = 'SHORT_TEXT' | 'LONG_TEXT' | ChoiceQuestionType;

export interface Question {
    id: string;
    question: string;
    description: string;
    required: boolean;
    type: QuestionType;
}

export interface ChoiceQuestion extends Question {
    type: ChoiceQuestionType;
    options: string[];
}

export const isChoiceQuestion = (question: Question): question is ChoiceQuestion => {
    return question.hasOwnProperty('options') && ['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(question.type);
};
