import { api } from './rootApi';
import type { Ticket } from '@/types/Ticket';
import type { AddToCart } from '@/types/Cart';

export const cartApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCartByUserId: builder.query<Ticket[], string>({
            query: (userId) => ({ url: `carts/${userId}`, method: 'GET' }),
            providesTags: ['Cart'],
        }),
        addTicketsToCart: builder.mutation<{ message: string; status: number }, AddToCart>({
            query: ({ userId, cartItems }) => ({
                url: `carts/${userId}`,
                method: 'POST',
                body: { cartItems },
            }),
            invalidatesTags: ['Cart'],
        }),
        removeTicketFromCart: builder.mutation<{ message: string; status: number }, string>({
            query: (eventItemId) => ({
                url: `carts/${eventItemId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Cart'],
        }),
        clearCart: builder.mutation<{ message: string; status: number }, string>({
            query: (userId) => ({
                url: `carts/${userId}/clear`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Cart'],
        }),
    }),
});

export const {
    useGetCartByUserIdQuery,
    useAddTicketsToCartMutation,
    useRemoveTicketFromCartMutation,
    useClearCartMutation,
} = cartApi;
