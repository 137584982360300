import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { RootState } from '@/app/store';
import type { PersistPartial } from 'redux-persist/es/persistReducer';
import type { EditForm } from '@/types/Form';

interface CurrentEditFormState {
    currentEditForm: EditForm | undefined;
}

const persistConfig = {
    key: 'currentEditForm',
    storage,
};

const initialState: CurrentEditFormState = {
    currentEditForm: undefined,
};

const currentEditFormSlice = createSlice({
    name: 'currentEditForm',
    initialState,
    reducers: {
        setCurrentEditForm(state, action: PayloadAction<EditForm>) {
            state.currentEditForm = action.payload;
        },
        clearCurrentEditForm(state) {
            state.currentEditForm = undefined;
        },
    },
});

export const { setCurrentEditForm, clearCurrentEditForm } = currentEditFormSlice.actions;
export const selectCurrentEditForm = (state: RootState): CurrentEditFormState & PersistPartial => state.currentEditForm;
export default persistReducer(persistConfig, currentEditFormSlice.reducer);
