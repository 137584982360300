import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import AdminRoot from '@/routes/admin/adminRoot';

import { store } from './app/store';
import { ThemeProvider } from './components/ThemeProvider';
import { CreateEvent } from './routes/admin/CreateEvent';
import AdminEvent from './routes/admin/Event';
import AdminEvents from './routes/admin/Events';
import SignIn from './routes/SignIn';
import SignUp from './routes/SignUp';
import { RequireAuth } from './utils/RequireAuth';
import { RequireNotAuth } from './utils/RequireNotAuth';
import Roles from './routes/admin/Roles';
import AdminOrganizations from './routes/admin/Organizations';
import { CreateOrganization } from './routes/admin/CreateOrganization';
import { RequireAdmin } from './utils/RequireAdmin';
import Role from './routes/admin/Role';
import { EditEvent } from './routes/admin/EditEvent';
import { Organization as AdminOrganization } from './routes/admin/Organization';
import { NotRepresentative } from './routes/NotRepresentative';
import { RequireRepresentative } from './utils/RequireRepresentative';
import { RequireNotRepresentative } from './utils/RequireNotRepresentative';
import { NotFound } from './routes/NotFound';
import { EditOrganization } from './routes/admin/EditOrganization';
import { AdminHome } from './routes/admin/AdminHome';
import { Home } from './routes/Home';
import { Root } from './routes/root';
import { Contact } from './routes/Contact';
import { PrivacyPolicy } from './routes/PrivacyPolicy';
import Users from './routes/admin/Users';
import { Events } from './routes/Events';
import { Event } from './routes/Event';
import Cart from './routes/Cart';
import OrderComplete from './routes/OrderComplete';
import Tickets from './routes/Tickets';
import SeatMapRoute from './routes/SeatMapRoute';
import SeatMaps from './routes/admin/SeatMaps';
import { CreateSeatmap } from './routes/admin/CreateSeatMap';
import SeatMapAdminRoute from './routes/admin/SeatMapAdminRoute';
import { InviteLink } from './routes/InviteLink';
import { JobApplication } from './routes/JobApplication';
import EventDetails from './routes/admin/EventDetails';
import Referrals from './routes/admin/Referrals';
import ReferralDetailPage from './routes/admin/ReferralDetailed';
import { About } from './routes/About';
import { Organizations } from './routes/Organizations';
import { Organization } from './routes/Organization';
import ScanList from './routes/ScanList';
import { ScanPage } from './routes/admin/ScanPage';
import AdminReceipts from './routes/admin/Receipts';
import { Places } from './routes/admin/Places';
import TicketForms from './routes/admin/TicketForms';
import CreateTicketForm from './routes/admin/CreateTicketForm';
import PreviewTicketForm from './routes/admin/PreviewTicketForm';
import EditTicketForm from './routes/admin/EditTicketForm';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: '',
                element: <Home />,
            },
            {
                path: '/events/:eventId',
                element: <Event />,
            },
            {
                path: '/events/:eventId/seat-map',
                element: <SeatMapRoute />,
            },
            {
                path: '/events',
                element: <Events />,
            },
            {
                path: '/organizations/:organizationId',
                element: <Organization />,
            },
            {
                path: '/organizations',
                element: <Organizations />,
            },
            {
                path: '/about',
                element: <About />,
            },
            {
                path: '/contact',
                element: <Contact />,
            },
            {
                path: '/apply',
                element: <JobApplication />,
            },
            {
                path: '/privacy-policy',
                element: <PrivacyPolicy />,
            },
            {
                path: '/invite/:inviteId',
                element: (
                    <RequireAuth>
                        <InviteLink />
                    </RequireAuth>
                ),
            },
            {
                path: '/sign-up',
                element: (
                    <RequireNotAuth>
                        <SignUp />
                    </RequireNotAuth>
                ),
            },
            {
                path: '/sign-in',
                element: (
                    <RequireNotAuth>
                        <SignIn />
                    </RequireNotAuth>
                ),
            },
            {
                path: '/cart',
                element: (
                    <RequireAuth>
                        <Cart />
                    </RequireAuth>
                ),
            },
            {
                path: '/tickets',
                element: (
                    <RequireAuth>
                        <Tickets />
                    </RequireAuth>
                ),
            },
            {
                path: '/order/complete',
                element: (
                    <RequireAuth>
                        <OrderComplete />
                    </RequireAuth>
                ),
            },
            {
                path: '/scanner',
                element: <ScanList />,
            },
            {
                path: '/scanner/:eventId',
                element: <ScanPage />,
            },
            {
                path: '/not-representative',
                element: (
                    <RequireAuth>
                        <RequireNotRepresentative>
                            <NotRepresentative />
                        </RequireNotRepresentative>
                    </RequireAuth>
                ),
            },
            {
                path: '/referrals/:id',
                element: (
                    <RequireAuth>
                        <ReferralDetailPage />
                    </RequireAuth>
                ),
            },
            {
                path: '/admin/forms/preview',
                element: (
                    <RequireAuth>
                        <RequireRepresentative>
                            <PreviewTicketForm />
                        </RequireRepresentative>
                    </RequireAuth>
                ),
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
    {
        path: '/admin',
        element: (
            <RequireAuth>
                <RequireRepresentative>
                    <AdminRoot />
                </RequireRepresentative>
            </RequireAuth>
        ),
        children: [
            {
                path: '',
                element: <AdminHome />,
            },
            {
                path: '/admin/receipts',
                element: <AdminReceipts />,
            },
            {
                path: '/admin/events',
                element: <AdminEvents />,
            },
            {
                path: '/admin/events/:eventId',
                element: <AdminEvent />,
            },
            {
                path: '/admin/events/:eventId/edit',
                element: <EditEvent />,
            },
            {
                path: '/admin/events/:eventId/details',
                element: <EventDetails />,
            },
            {
                path: '/admin/events/create',
                element: <CreateEvent />,
            },
            {
                path: '/admin/forms',
                element: <TicketForms />,
            },
            {
                path: '/admin/forms/create',
                element: <CreateTicketForm />,
            },
            {
                path: '/admin/forms/:formId',
                element: <EditTicketForm />,
            },
            {
                path: '/admin/roles',
                element: <Roles />,
            },
            {
                path: '/admin/roles/:id',
                element: <Role />,
            },
            {
                path: '/admin/places',
                element: <Places />,
            },
            {
                path: '/admin/organization',
                element: <AdminOrganization />,
            },
            {
                path: '/admin/organization/edit',
                element: <EditOrganization />,
            },
            {
                path: '/admin/referrals',
                element: <Referrals />,
            },
            {
                path: '/admin/seat-maps',
                element: (
                    <RequireAdmin>
                        <SeatMaps />
                    </RequireAdmin>
                ),
            },
            {
                path: '/admin/seat-maps/:seatMapId',
                element: (
                    <RequireAdmin>
                        <SeatMapAdminRoute />
                    </RequireAdmin>
                ),
            },
            {
                path: '/admin/seat-maps/create',
                element: (
                    <RequireAdmin>
                        <CreateSeatmap />
                    </RequireAdmin>
                ),
            },
            {
                path: '/admin/users',
                element: (
                    <RequireAdmin>
                        <Users />
                    </RequireAdmin>
                ),
            },
            {
                path: '/admin/organizations',
                element: (
                    <RequireAdmin>
                        <AdminOrganizations />
                    </RequireAdmin>
                ),
            },
            {
                path: '/admin/organizations/create',
                element: (
                    <RequireAdmin>
                        <CreateOrganization />
                    </RequireAdmin>
                ),
            },
        ],
    },
]);

const root = document.getElementById('root');
if (root) {
    ReactDOM.createRoot(root).render(
        <React.StrictMode>
            <ThemeProvider defaultTheme="system">
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>
            </ThemeProvider>
        </React.StrictMode>,
    );
}
