import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBase } from './fetchBase';

export const api = createApi({
    tagTypes: [
        'Event',
        'EventItem',
        'Organization',
        'Place',
        'Role',
        'Ticket',
        'UserTicket',
        'User',
        'SeatMap',
        'Referral',
        'Stat',
        'Receipt',
        'Cart',
        'Form',
    ],
    reducerPath: 'api',
    baseQuery: fetchBase,
    endpoints: () => ({}),
});
