import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Circle, GripVertical, Plus, Square, Trash, X } from 'lucide-react';
import { Select, SelectItem, SelectValue, SelectContent, SelectTrigger } from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cn } from '@/lib/utils';
import { DeleteDialog } from '@/components/ui/DeleteDialog';
import { isChoiceQuestion, type ChoiceQuestion, type Question, type QuestionType } from '@/types/Form';
import { Textarea } from '@/components/ui/textarea';
import { Switch } from '@/components/ui/Switch';

interface FormQuestionProps {
    question: Question;
    onChange: (id: string, key: keyof Question, value: string | boolean | QuestionType) => void;
    onAddOption: (id: string) => void;
    onChangeOption: (id: string, index: number, value: string) => void;
    onDeleteOption: (id: string, index: number) => void;
    onDelete: (id: string) => Promise<void>;
}

export const EditFormQuestion = ({
    question,
    onChange,
    onAddOption,
    onChangeOption,
    onDeleteOption,
    onDelete,
}: FormQuestionProps): JSX.Element => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: question.id });

    const style = {
        transform: transform ? CSS.Translate.toString(transform) : undefined,
        transition,
    };

    return (
        <Card className={cn(isDragging && 'z-30 shadow-md')} ref={setNodeRef} style={style}>
            <CardHeader className="flex flex-row items-center gap-2 justify-between">
                <div className="flex flex-row gap-2 items-center">
                    <Button
                        className={cn(isDragging && 'bg-gray-500/20 primary-foreground/90')}
                        variant="ghost"
                        size="icon"
                        {...attributes}
                        {...listeners}
                    >
                        <GripVertical className="h-5 w-5" />
                    </Button>
                    <Input
                        placeholder="Question"
                        value={question.question}
                        onChange={(e) => onChange(question.id, 'question', e.target.value)}
                        className="max-w-72"
                    />
                </div>
                <div className="flex flex-row items-center gap-2">
                    <div className="min-w-fit flex flex-row gap-1 items-center">
                        Required
                        <Switch
                            checked={question.required}
                            onClick={() => onChange(question.id, 'required', !question.required)}
                        />
                    </div>

                    <Select
                        onValueChange={(value: QuestionType) => onChange(question.id, 'type', value)}
                        defaultValue={question.type}
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Select type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="SHORT_TEXT">Short Text</SelectItem>
                            <SelectItem value="LONG_TEXT">Long Text</SelectItem>
                            <SelectItem value="SINGLE_CHOICE">Single Choice</SelectItem>
                            <SelectItem value="MULTIPLE_CHOICE">Multiple Choice</SelectItem>
                        </SelectContent>
                    </Select>
                    <DeleteDialog deleteItem={() => onDelete(question.id)} successMessage="Question was deleted">
                        <Button variant="destructive" size="icon" className="m-0">
                            <Trash className="h-5 w-5" />
                        </Button>
                    </DeleteDialog>
                </div>
            </CardHeader>
            <CardContent className="flex flex-col pb-4 gap-2">
                <Textarea
                    placeholder="Description"
                    value={question.description}
                    onChange={(e) => onChange(question.id, 'description', e.target.value)}
                />
                {isChoiceQuestion(question) && (
                    <FormQuestionOptions
                        question={question}
                        onAdd={onAddOption}
                        onChange={onChangeOption}
                        onDelete={onDeleteOption}
                    />
                )}
            </CardContent>
        </Card>
    );
};

interface FormQuestionOptionsProps {
    question: ChoiceQuestion;
    onAdd: (id: string) => void;
    onChange: (id: string, index: number, value: string) => void;
    onDelete: (id: string, index: number) => void;
}

const FormQuestionOptions = ({ question, onAdd, onChange, onDelete }: FormQuestionOptionsProps) => {
    return (
        <div className="flex flex-col gap-2">
            {question.options.map((option, index) => (
                <div key={`${question.id}-option-${index}`} className="flex flex-row items-center gap-2">
                    {question.type === 'MULTIPLE_CHOICE' && <Square className="h-5 w-5 text-border" />}
                    {question.type === 'SINGLE_CHOICE' && <Circle className="h-5 w-5 text-border" />}
                    <Input
                        className="flex-1"
                        placeholder={`Option ${index + 1}`}
                        value={option}
                        onChange={(e) => {
                            onChange(question.id, index, e.target.value);
                        }}
                    />
                    <Button size="iconSm" variant="destructiveOutline" onClick={() => onDelete(question.id, index)}>
                        <X className="h-4 w-4" />
                    </Button>
                </div>
            ))}
            <Button variant="secondary" onClick={() => onAdd(question.id)}>
                <Plus className="h-5 w-5 mr-2" />
                <p>Add option</p>
            </Button>
        </div>
    );
};
