import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    type DragEndEvent,
} from '@dnd-kit/core';
import { CenteredSpinner } from '@/components/ui/spinner';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { PageHeader } from '@/components/PageHeader';
import { Input } from '@/components/ui/input';
import { EditFormQuestion } from '@/components/Forms/EditFormQuestion';
import { useEffect, useState } from 'react';
import { isChoiceQuestion, type ChoiceQuestion, type Question, type QuestionType } from '@/types/Form';
import { useCurrentOrganization } from '@/hooks/apiHooks';
import { errorToast, errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { nanoid } from '@reduxjs/toolkit';
import { useEditFormMutation, useGetFormByIdQuery } from '@/services/formsApi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@/hooks/store';
import {
    setCurrentEditForm,
    selectCurrentEditForm,
    clearCurrentEditForm,
} from '@/features/currentEditForm/currentEditFormSlice';

export default function EditTicketForm(): JSX.Element {
    const navigate = useNavigate();
    const { formId } = useParams<{ formId: string }>();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const { currentEditForm } = useAppSelector(selectCurrentEditForm);
    const { data, isLoading } = useGetFormByIdQuery(formId ?? '', { skip: !formId });
    const currentOrganization = useCurrentOrganization();
    const [editForm, editFormState] = useEditFormMutation();
    const [name, setName] = useState('');
    const [questions, setQuestions] = useState<Question[]>([]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const addQuestion = () => {
        const newQuestion: Question = {
            id: nanoid(8),
            type: 'SHORT_TEXT',
            question: '',
            description: '',
            required: false,
        };

        setQuestions((oldQuestions) => [...oldQuestions, newQuestion]);
    };

    const removeQuestion = async (id: string) => {
        setQuestions((oldQuestions) => oldQuestions.filter((question) => question.id !== id));
    };

    const handleQuestionChange = (id: string, key: keyof Question, value: string | boolean | QuestionType) => {
        setQuestions((oldQuestions) =>
            oldQuestions.map((question) => {
                if (question.id === id) {
                    if (key === 'type') {
                        if (value === 'SINGLE_CHOICE' || value === 'MULTIPLE_CHOICE') {
                            return { ...question, [key]: value, options: [''] };
                        } else {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const { options, ...rest } = question as ChoiceQuestion; // Destructure to remove options
                            const newQuestion: Question = { ...rest, [key]: value as QuestionType };
                            return newQuestion;
                        }
                    }
                    return { ...question, [key]: value };
                }

                return question;
            }),
        );
    };

    const handleAddQuestionOption = (id: string) => {
        setQuestions((oldQuestions) =>
            oldQuestions.map((question) => {
                if (question.id === id && isChoiceQuestion(question)) {
                    return { ...question, options: [...question.options, ''] };
                }

                return question;
            }),
        );
    };

    const handleQuestionOptionChange = (id: string, optionIndex: number, value: string) => {
        setQuestions((oldQuestions) =>
            oldQuestions.map((question) => {
                if (question.id === id && isChoiceQuestion(question)) {
                    const newOptions = [...question.options];
                    newOptions[optionIndex] = value;
                    return { ...question, options: newOptions };
                }

                return question;
            }),
        );
    };

    const handleDeleteQuestionOption = (id: string, optionIndex: number) => {
        setQuestions((oldQuestions) =>
            oldQuestions.map((question) => {
                if (question.id === id && isChoiceQuestion(question)) {
                    const newOptions = [...question.options];
                    newOptions.splice(optionIndex, 1);
                    return { ...question, options: newOptions };
                }

                return question;
            }),
        );
    };

    const handleOnSubmit = () => {
        if (!currentOrganization) {
            errorToast('No organization selected');
            return;
        }
        if (!formId) {
            errorToast('Form id is required');
            return;
        }
        if (!name) {
            errorToast('Form name is required');
            return;
        }
        const form = {
            id: formId,
            name,
            template: questions,
        };
        editForm(form)
            .unwrap()
            .then((f) => {
                successToast(`Form ${f.name} updated successfully`);
                dispatch(clearCurrentEditForm());
                navigate('/admin/forms');
            })
            .catch(errorToastFromCatch);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            setQuestions((oldItems) => {
                const oldIndex = oldItems.map((q) => q.id).indexOf(active?.id as string);
                const newIndex = oldItems.map((q) => q.id).indexOf(over?.id as string);

                return arrayMove(oldItems, oldIndex, newIndex);
            });
        }
    };

    const handlePreview = () => {
        if (!formId) {
            return;
        }
        dispatch(setCurrentEditForm({ id: formId, name, template: questions }));
        navigate('/admin/forms/preview');
    };

    useEffect(() => {
        if (!searchParams.get('preview') && data) {
            setName(data.name);
            setQuestions(data.template);
            return;
        }
        if (currentEditForm) {
            setName(currentEditForm.name);
            setQuestions(currentEditForm.template);
        }
    }, [data]);

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (!data) {
        return <div>Error fetching ticket form</div>;
    }

    return (
        <div className="flex flex-col items-center">
            <PageHeader>
                <Input
                    placeholder="Name"
                    className="w-full md:max-w-64"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <div className="flex flex-row gap-2">
                    <Button variant="secondary" onClick={handlePreview}>
                        Preview
                    </Button>
                    <Button isLoading={editFormState.isLoading} onClick={handleOnSubmit}>
                        Save
                    </Button>
                </div>
            </PageHeader>
            <div className="w-full flex flex-col justify-center gap-4 p-8">
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToVerticalAxis]}
                >
                    <SortableContext items={questions} strategy={verticalListSortingStrategy}>
                        {questions.map((question) => (
                            <EditFormQuestion
                                key={question.id}
                                question={question}
                                onChange={handleQuestionChange}
                                onAddOption={handleAddQuestionOption}
                                onChangeOption={handleQuestionOptionChange}
                                onDeleteOption={handleDeleteQuestionOption}
                                onDelete={removeQuestion}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
                <Button onClick={addQuestion} size="lg" className="w-full">
                    <Plus className="h-5 w-5 mr-2" />
                    <p>Add question</p>
                </Button>
            </div>
        </div>
    );
}
