import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { isChoiceQuestion, type Question } from '@/types/Form';
import { Input, TextArea } from '@/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';
import { Checkbox } from '@/components/ui/checkbox';

interface FormQuestionProps {
    question: Question;
}

export const FormQuestion = ({ question }: FormQuestionProps): JSX.Element => {
    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle>{question.question}</CardTitle>
                {question.required && <p className="font-semibold text-sm text-secondary">Required</p>}
            </CardHeader>
            <CardContent className="flex flex-col pb-4 gap-4">
                {question.description && <p>{question.description}</p>}
                {question.type === 'SHORT_TEXT' && <Input />}
                {question.type === 'LONG_TEXT' && <TextArea />}
                {question.type === 'SINGLE_CHOICE' && isChoiceQuestion(question) && (
                    <RadioGroup>
                        {question.options.map((option, index) => (
                            <div key={`${option}-${index}`} className="flex items-center gap-1">
                                <RadioGroupItem value={option} id={option} />
                                <Label htmlFor={option}>{option}</Label>
                            </div>
                        ))}
                    </RadioGroup>
                )}
                {question.type === 'MULTIPLE_CHOICE' && isChoiceQuestion(question) && (
                    <div className="flex flex-col gap-2">
                        {question.options.map((option, index) => (
                            <div key={`${option}-${index}`} className="flex items-center gap-1">
                                <Checkbox value={option} id={option} />
                                <Label htmlFor={option}>{option}</Label>
                            </div>
                        ))}
                    </div>
                )}
            </CardContent>
        </Card>
    );
};
