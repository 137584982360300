import {
    Calendar,
    HomeIcon,
    MapIcon,
    PanelLeftCloseIcon,
    PanelLeftOpenIcon,
    TicketIcon,
    UserCog,
    Users,
    Building2Icon,
    SofaIcon,
    PocketKnifeIcon,
    ScanQrCode,
    FilePenLine,
} from 'lucide-react';
import { type PropsWithChildren, type ReactNode, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { setIsNavigationExpanded, toogleIsNavigationExpanded } from '@/features/settings/settingsSlice';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { cn } from '@/lib/utils';

import { Button } from './ui/button';
import { Input } from './ui/input';
import { useAuth, useIsAdmin } from '@/hooks/apiHooks';
import { Separator } from './ui/separator';
import { MeiliTasksDialog } from './MeiliTasksDialog';

export default function Navigation(): JSX.Element {
    const isExpanded = useAppSelector((state) => state.settings.isNavigationExpanded);
    const isAdmin = useIsAdmin();
    const dispatch = useAppDispatch();
    const { accessToken } = useAuth();

    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'b' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                dispatch(toogleIsNavigationExpanded());
            }
        };

        document.addEventListener('keydown', down);
        return () => document.removeEventListener('keydown', down);
    }, []);

    return (
        <nav
            className={cn(
                'h-full border-r transition-[min-width] p-2 sticky left-0 top-0 bg-background',
                isExpanded ? 'min-w-[48px] lg:min-w-[240px]' : 'min-w-[48px]',
            )}
        >
            <div className="flex flex-col h-full justify-between">
                <ul className="justify-start space-y-2">
                    <NavigationLink icon={<HomeIcon className="h-5 w-5" />} to="/admin" end expanded={isExpanded}>
                        Home
                    </NavigationLink>
                    <NavigationLink icon={<Calendar className="h-5 w-5" />} to="/admin/events" expanded={isExpanded}>
                        Events
                    </NavigationLink>
                    <NavigationLink icon={<FilePenLine className="h-5 w-5" />} to="/admin/forms" expanded={isExpanded}>
                        Forms
                    </NavigationLink>
                    <NavigationLink icon={<UserCog className="h-5 w-5" />} to="/admin/roles" expanded={isExpanded}>
                        Roles
                    </NavigationLink>
                    <NavigationLink
                        icon={<TicketIcon className="h-5 w-5" />}
                        to="/admin/receipts"
                        expanded={isExpanded}
                    >
                        Receipts
                    </NavigationLink>
                    <NavigationLink icon={<MapIcon className="h-5 w-5" />} to="/admin/places" expanded={isExpanded}>
                        Places
                    </NavigationLink>
                    <NavigationLink icon={<Users className="h-5 w-5" />} to="/admin/referrals" expanded={isExpanded}>
                        Referrals
                    </NavigationLink>
                    <NavigationLink icon={<ScanQrCode className="h-5 w-5" />} to="/scanner" expanded={isExpanded}>
                        Scanner
                    </NavigationLink>

                    {isAdmin && (
                        <>
                            <Separator />
                            <NavigationLink
                                icon={<SofaIcon className="h-5 w-5" />}
                                to="/admin/seat-maps"
                                expanded={isExpanded}
                            >
                                Seat maps
                            </NavigationLink>
                            <NavigationLink
                                icon={<Users className="h-5 w-5" />}
                                to="/admin/users"
                                expanded={isExpanded}
                            >
                                Users
                            </NavigationLink>
                            <NavigationLink
                                icon={<Building2Icon className="h-5 w-5" />}
                                to="/admin/organizations"
                                expanded={isExpanded}
                            >
                                Organizations
                            </NavigationLink>
                            <Separator />
                            <MeiliTasksDialog>
                                <NavigationItem icon={<PocketKnifeIcon className="h-5 w-5" />} expanded={isExpanded}>
                                    Meili tasks
                                </NavigationItem>
                            </MeiliTasksDialog>
                        </>
                    )}
                </ul>
                <ul>
                    {import.meta.env.DEV && isExpanded && (
                        <li className="mb-2 hidden lg:block">
                            <Input defaultValue={accessToken} />
                        </li>
                    )}
                    {isExpanded ? (
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => dispatch(setIsNavigationExpanded(false))}
                            className="p-2 h-9 w-9 hidden lg:block"
                        >
                            <PanelLeftCloseIcon className="h-5 w-5" />
                        </Button>
                    ) : (
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => dispatch(setIsNavigationExpanded(true))}
                            className="p-2 h-9 w-9 hidden lg:block"
                        >
                            <PanelLeftOpenIcon className="h-5 w-5" />
                        </Button>
                    )}
                </ul>
            </div>
        </nav>
    );
}

interface NavigationLinkProps {
    icon: ReactNode;
    to: string;
    expanded?: boolean;
    active?: boolean;
    end?: boolean;
}

function NavigationLink({ children, icon, to, expanded, end }: PropsWithChildren<NavigationLinkProps>) {
    return (
        <li>
            <NavLink to={to} end={end}>
                {({ isActive }) => (
                    <div
                        className={cn(
                            'rounded-sm hover:bg-accent flex items-center p-2 cursor-pointer',
                            isActive && 'bg-accent',
                        )}
                    >
                        {icon}
                        {expanded && (
                            <p
                                className="ml-2 hidden lg:block"
                                style={{
                                    lineHeight: '1.25rem',
                                }}
                            >
                                {children}
                            </p>
                        )}
                    </div>
                )}
            </NavLink>
        </li>
    );
}

interface NavigationItemProps {
    icon: ReactNode;
    expanded?: boolean;
}

function NavigationItem({ children, icon, expanded }: PropsWithChildren<NavigationItemProps>) {
    return (
        <li>
            <div className={'rounded-sm hover:bg-accent flex items-center p-2 cursor-pointer'}>
                {icon}
                {expanded && (
                    <p
                        className="ml-2 hidden lg:block"
                        style={{
                            lineHeight: '1.25rem',
                        }}
                    >
                        {children}
                    </p>
                )}
            </div>
        </li>
    );
}
