import { Drawer, DrawerClose, DrawerContent, DrawerFooter, DrawerTrigger } from '@/components/ui/drawer';
import { Button } from '@/components/ui/button';
import type { EventItemSmall } from '@/types/EventItem';
import { MinusIcon, PlusIcon } from 'lucide-react';
import { useState, type ReactNode } from 'react';
import { useAddTicketsToCartMutation } from '@/services/cartApi';
import { useAuth } from '@/hooks/apiHooks';
import { errorToastFromCatch, successToast } from './ui/use-toast';
import { formatPrice } from '@/lib/utils';
import type { CartItem } from '@/types/Cart';
import { BasicTooltip } from './ui/tooltip';
import { Input } from './ui/input';
import { useNavigate } from 'react-router-dom';

interface BuyButtonProps {
    eventItems: EventItemSmall[];
    children: ReactNode;
}

type TicketAmounts = Record<string, number>;
type CustomPriceRecord = Record<string, number>;

export const BuyDrawer = ({ eventItems, children }: BuyButtonProps): JSX.Element => {
    const navigate = useNavigate();
    const [customPrices, setCustomPrices] = useState<CustomPriceRecord>(
        eventItems.reduce((prices, item) => {
            prices[item.id] = item.price;
            return prices;
        }, {} as CustomPriceRecord),
    );
    const { user } = useAuth();
    const [ticketAmounts, setTicketAmounts] = useState<TicketAmounts>({
        ...eventItems.reduce((acc, item) => ({ ...acc, [item.id]: 0 }), {} as TicketAmounts),
    });
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [addToCart, response] = useAddTicketsToCartMutation();

    const handleAddToCart = () => {
        if (user) {
            const cartItems: CartItem[] = [];
            Object.entries(ticketAmounts).forEach(([eventItemId, amount]) => {
                for (let i = 0; i < amount; i++) {
                    cartItems.push({ eventItemId, actualPrice: customPrices[eventItemId] });
                }
            });

            addToCart({
                userId: user.id,
                cartItems,
            })
                .unwrap()
                .then(() => {
                    successToast('Tickets added to cart', { onClick: () => navigate('/cart'), label: 'Go to cart' });
                    setDrawerOpen(false);
                    setTicketAmounts({
                        ...eventItems.reduce((acc, item) => ({ ...acc, [item.id]: 0 }), {} as TicketAmounts),
                    });
                })
                .catch(errorToastFromCatch);
        }
    };

    return (
        <Drawer onOpenChange={setDrawerOpen} open={drawerOpen}>
            <DrawerTrigger className="w-full" asChild>
                {children}
            </DrawerTrigger>
            <DrawerContent>
                <div className="p-4 space-y-4">
                    {eventItems.map((item) => {
                        const ticketAmount = ticketAmounts[item.id] ?? 0;
                        return (
                            <div key={item.id} className="flex justify-between items-center space-x-4">
                                <div>
                                    <h2 className="font-bold">{item.name}</h2>
                                    <p className="font-medium">
                                        {item.hasMinimumPrice
                                            ? `Minimum ${formatPrice(item.price)}`
                                            : formatPrice(item.price)}{' '}
                                        SEK
                                    </p>
                                    {item.limit && <p>Max {item.limit} per person</p>}
                                </div>
                                <div className="flex items-center space-x-4">
                                    {ticketAmount > 0 && (
                                        <Button
                                            variant="outline"
                                            onClick={() => setTicketAmounts({ ...ticketAmounts, [item.id]: 0 })}
                                        >
                                            Reset
                                        </Button>
                                    )}
                                    {item.hasMinimumPrice && (
                                        <BasicTooltip
                                            tooltip={`Minimum price is ${formatPrice(
                                                item.price,
                                            )} SEK, but feel free to pay more if you want to show your support`}
                                        >
                                            <div className="flex flex-row items-center gap-2">
                                                <Input
                                                    type="number"
                                                    step={10}
                                                    min={formatPrice(item.price)}
                                                    className="w-24"
                                                    value={formatPrice(customPrices[item.id] ?? 0)}
                                                    onChange={(e) => {
                                                        setCustomPrices({
                                                            ...customPrices,
                                                            [item.id]: Number(e.target.value) * 100,
                                                        });
                                                    }}
                                                />
                                                SEK
                                            </div>
                                        </BasicTooltip>
                                    )}
                                    <Button
                                        disabled={ticketAmount === 0}
                                        size="icon"
                                        onClick={() => {
                                            if (ticketAmount > 0) {
                                                setTicketAmounts({
                                                    ...ticketAmounts,
                                                    [item.id]: ticketAmount - 1,
                                                });
                                            }
                                        }}
                                    >
                                        <MinusIcon className="h-5 w-5" />
                                    </Button>
                                    <div className="flex justify-center w-4">
                                        <p>{ticketAmount}</p>
                                    </div>
                                    <Button
                                        disabled={item.ticketsLeftUser !== null && ticketAmount >= item.ticketsLeftUser}
                                        size="icon"
                                        onClick={() => {
                                            if (item.ticketsLeftUser === null || ticketAmount < item.ticketsLeftUser) {
                                                setTicketAmounts({
                                                    ...ticketAmounts,
                                                    [item.id]: ticketAmount + 1,
                                                });
                                            }
                                        }}
                                    >
                                        <PlusIcon className="h-5 w-5" />
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <DrawerFooter>
                    <Button onClick={handleAddToCart} isLoading={response.isLoading}>
                        Add to cart
                    </Button>
                    <DrawerClose>
                        <Button variant="outline">Cancel</Button>
                    </DrawerClose>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};
